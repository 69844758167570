<template>
	<nav class="col-md-2 d-none d-md-block bg-light sidebar">
		<div class="sidebar-sticky">
			<ul class="nav flex-column pt-2">
				<li class="nav-item">
					<router-link
						:to="$GLOBALS.PATH_DASHBOARD"
						class="nav-link"
					>
						Dashboard
					</router-link>
				</li>		
				<li v-if="showAdminLinks" class="nav-item">
					<router-link
						:to="$GLOBALS.PATH_ADMINS"
						class="nav-link"
					>
						Admins
					</router-link>
				</li>		
				<li v-if="showAdminLinks" class="nav-item">
					<router-link
						:to="$GLOBALS.PATH_ADMIN_CREATE"
						class="nav-link"
					>
						Create admin
					</router-link>
				</li>		
			</ul>
		</div>
	</nav>
</template>

<script>
// State
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters([
			'isSuperAdmin'
		]),
		showAdminLinks() {
			return this.isSuperAdmin === 'true' || this.isSuperAdmin === true
		}
	}
}
</script>