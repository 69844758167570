<template>
	<tr>
		<td class="w-40 align-top" scope="row">
			<strong>{{ name }}<span class="sr-only">:</span></strong>
		</td>
		<td
			:id="`entry-content-${id}`"
			class="w-40 align-top"
			v-html="value"
		/>
		<td class="w-40 align-top text-right">
			<!-- Copy entry value -->
			<button
				class="btn btn-sm btn-outline-primary"
				title="Copy"
				@click="copyValue(`entry-content-${id}`)"
			>
				<copy-icon size="1x"></copy-icon>
			</button>
		</td>
	</tr>
</template>

<script>
// Icons
import { CopyIcon } from 'vue-feather-icons'

export default {
	props: {
		name: {
			default: '',
			type: String
		},
		value: {
			default: false
		}
	},
	components: {
		CopyIcon
	},
	computed: {
		id() {
			let id = ''
			// Lowercase object name
			id = this.name.toLowerCase()
			// Return ID & add hyphens
			return id.replace(/ /g, '-')
		}
	},
	methods: {
		copyValue(id) {
			this.$emit('copy-value', id)
		},
	}
}
</script>