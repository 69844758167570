<template>
	<dashboard-container
		:toolbar="getAdminError ? false : true"
		:title="fullName"
	>
		<section v-if="!getAdminError" class="h-auto pb-5">
			<div class="my-4">
				<!-- Highlight user risks -->
				<div v-if="safeguarding" class="mt-4">
					<alert
						v-for="(risk, i) in safeguarding"
						:key="i"
						type="danger"
						class="px-3 py-2"
					>
						<small>{{ risk.human }}</small>
					</alert>
				</div>
			</div>
			<div class="table-responsive">
				<table class="table table-md">
					<caption>User Information</caption>
					<!-- Placeholder data -->
					<tbody v-if="loading" class="table-loading">
						<tr v-for="tr in 5" :key="tr">
							<td v-for="td in 3" :key="td">
								<span></span>
								<span class="sr-only">Loading...</span>
							</td>
						</tr>
					</tbody>
					<!-- User Entries -->
					<tbody v-else id="user-information">
						<!-- Appointment -->
						<UserInformation
							v-if="userEntry.appointment_timestamp"
							name="Appointment date"
							:value="userEntry.appointment_timestamp"
							@copy-value="copyValue"
						/>
						<UserInformation
							v-if="userEntry.appointment_timestamp"
							name="Appointment time"
							:value="appointmentTime(time)"
							@copy-value="copyValue"
						/>
						<!-- Clinic -->
						<UserInformation
							v-if="userEntry.clinic"
							name="Clinic"
							:value="userEntry.clinic"
							@copy-value="copyValue"
						/>
						<!-- Reason for visit -->
						<UserInformation
							v-if="userEntry.reason_for_visit.length"
							name="Reason for visit"
							:value="userEntry.reason_for_visit"
							@copy-value="copyValue"
						/>
						<!-- First name -->
						<UserInformation
							v-if="userEntry.first_name"
							name="First name"
							:value="userEntry.first_name"
							@copy-value="copyValue"
						/>
						<!-- Last name -->
						<UserInformation
							v-if="userEntry.last_name"
							name="Last name"
							:value="userEntry.last_name"
							@copy-value="copyValue"
						/>
						<!-- Preferred name -->
						<UserInformation
							v-if="userEntry.preferred_name"
							name="Preferred name"
							:value="userEntry.preferred_name"
							@copy-value="copyValue"
						/>
						<!-- Date of birth -->
						<UserInformation
							v-if="userEntry.date_of_birth"
							name="Date of birth"
							:value="userEntry.date_of_birth"
							@copy-value="copyValue"
						/>
						<!-- Postcode -->
						<UserInformation
							v-if="userEntry.postcode"
							name="Postcode"
							:value="userEntry.postcode"
							@copy-value="copyValue"
						/>
						<!-- Contact number -->
						<UserInformation
							v-if="userEntry.contact_number"
							name="Contact number"
							:value="userEntry.contact_number"
							@copy-value="copyValue"
						/>
						<!-- Ethnicity -->
						<UserInformation
							v-if="userEntry.ethnicity"
							name="Ethnicity"
							:value="userEntry.ethnicity"
							@copy-value="copyValue"
						/>
						<!-- Sexuality -->
						<UserInformation
							v-if="userEntry.sexuality"
							name="Sexuality"
							:value="userEntry.sexuality"
							@copy-value="copyValue"
						/>
						<!-- Sex at birth -->
						<UserInformation
							v-if="userEntry.sex_at_birth"
							name="Sex at birth"
							:value="userEntry.sex_at_birth"
							@copy-value="copyValue"
						/>
						<!-- Preferred pronoun -->
						<UserInformation
							v-if="userEntry.preferred_pronoun"
							name="Preferred pronoun"
							:value="userEntry.preferred_pronoun"
							@copy-value="copyValue"
						/>
						<!-- Relationship status -->
						<UserInformation
							v-if="userEntry.relationship_status.length"
							name="Relationship status"
							:value="userEntry.relationship_status"
							@copy-value="copyValue"
						/>
						<!-- Age of partner -->
						<UserInformation
							v-if="userEntry.age_of_partner"
							name="Age of partner"
							:value="userEntry.age_of_partner"
							@copy-value="copyValue"
						/>
						<!-- Person genital -->
						<UserInformation
							v-if="userEntry.person_genitals"
							name="Person genitals"
							:value="userEntry.person_genitals"
							@copy-value="copyValue"
						/>
						<!-- Has had sex -->
						<UserInformation
							v-if="userEntry.has_had_sex"
							name="Has had sex"
							:value="userEntry.has_had_sex"
							@copy-value="copyValue"
						/>
						<!-- Has had upsi -->
						<UserInformation
							v-if="userEntry.has_had_upsi"
							name="Has had upsi"
							:value="userEntry.has_had_upsi"
							@copy-value="copyValue"
						/>
						<!-- Contraceptive methods -->
						<UserInformation
							v-if="userEntry.contraceptive_methods.length"
							name="Contraceptive methods"
							:value="userEntry.contraceptive_methods"
							@copy-value="copyValue"
						/>
						<!-- Suspected contaception failure -->
						<UserInformation
							v-if="userEntry.suspected_contraception_failure"
							name="Suspected contaception failure"
							:value="userEntry.suspected_contraception_failure"
							@copy-value="copyValue"
						/>
						<!-- Employment status -->
						<UserInformation
							v-if="userEntry.employment_status.length"
							name="Employment status"
							:value="userEntry.employment_status"
							@copy-value="copyValue"
						/>
						<!-- Has missed school days -->
						<UserInformation
							v-if="userEntry.has_missed_school_days"
							name="Has missed school days"
							:value="userEntry.has_missed_school_days"
							@copy-value="copyValue"
						/>
						<!-- Number of school days missed -->
						<UserInformation
							v-if="userEntry.number_of_school_days_missed"
							name="Number of school days missed"
							:value="userEntry.number_of_school_days_missed"
							@copy-value="copyValue"
						/>
						<!-- Living with -->
						<UserInformation
							v-if="userEntry.living_with"
							name="Living with"
							:value="userEntry.living_with"
							@copy-value="copyValue"
						/>
						<!-- Day to day support -->
						<UserInformation
							v-if="userEntry.day_to_day_support.length"
							name="Day to day support"
							:value="userEntry.day_to_day_support"
							@copy-value="copyValue"
						/>
						<!-- Intoxicants used -->
						<UserInformation
							v-if="userEntry.intoxicants_used.length"
							name="Intoxicants used"
							:value="userEntry.intoxicants_used"
							@copy-value="copyValue"
						/>
						<!-- Internet tech currently used -->
						<UserInformation
							v-if="userEntry.internet_tech_currently_used.length"
							name="Internet tech currently used"
							:value="userEntry.internet_tech_currently_used"
							@copy-value="copyValue"
						/>
						<!-- Feeling today -->
						<UserInformation
							v-if="userEntry.feeling_today"
							name="Feeling today"
							:value="userEntry.feeling_today"
							@copy-value="copyValue"
						/>
						<!-- Have you experienced -->
						<UserInformation
							v-if="userEntry.have_you_experienced.length"
							name="Have you experienced"
							:value="userEntry.have_you_experienced"
							@copy-value="copyValue"
						/>
						<!-- Upsi dates -->
						<UserInformation
							v-if="userEntry.upsi_dates.length"
							name="Upsi dates"
							:value="userEntry.upsi_dates"
							@copy-value="copyValue"
						/>
						<!-- Upsi risk date -->
						<UserInformation
							v-if="userEntry.upsi_risk_date"
							name="Upsi risk date"
							:value="userEntry.upsi_risk_date"
							@copy-value="copyValue"
						/>
						<!-- Period first day -->
						<UserInformation
							v-if="userEntry.period_first_day"
							name="Period first day"
							:value="userEntry.period_first_day"
							@copy-value="copyValue"
						/>
						<!-- Cycle length -->
						<UserInformation
							v-if="userEntry.cycle_length"
							name="Cycle length"
							:value="userEntry.cycle_length"
							@copy-value="copyValue"
						/>
						<!-- Pregnancy risk -->
						<UserInformation
							v-if="userEntry.pregnancy_risk"
							name="Pregnancy risk"
							:value="userEntry.pregnancy_risk"
							@copy-value="copyValue"
						/>
						<!-- Ec eligibility -->
						<UserInformation
							v-if="userEntry.ec_eligibility"
							name="Ec eligibility"
							:value="userEntry.ec_eligibility"
							@copy-value="copyValue"
						/>
					</tbody>
				</table>
			</div>

			<div class="row">
				<div class="col">
					<tool-bar class="justify-content-end" />
				</div>
			</div>

		</section>
		<section v-else class="h-100">
			<alert type="danger">
				<small>{{ getAdminError}}</small>
			</alert>
		</section>

		<transition name="alert">
			<alert v-show="copied"
				type="success"
				class="fixed-bottom mr-4"
			>
				<small>Copied to clipboard</small>
		</alert>
		</transition>
	</dashboard-container>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Admin Components
import dashboardContainer from '@/components/admin/dashboard-container'
import toolBar from '@/components/admin/tool-bar'
import UserInformation from '@/components/admin/user-information'

// General Components
import alert from '@/components/general/alert'

export default {
	components: {
		dashboardContainer,
		toolBar,
		UserInformation,
		alert
	},
	data() {
		return {
			userId: '',
			loading: true,
			error: '',
			copied: false,
			entry: '',
			time: ''
		}
	},
	computed: {
		...mapGetters([
			'getAdminError',
			'getUserClinic'
		]),
		userEntry() {
			let user = []

			// Get entry to convert any values
			if ( this.entry ) {
				user = this.entry
				user.appointment_timestamp = this.appointmentDate(user.appointment_timestamp)
				user.reason_for_visit = this.entryValue(user.reason_for_visit)
				user.relationship_status = this.entryValue(user.relationship_status)
				user.contraceptive_methods = this.entryValue(user.contraceptive_methods)
				user.employment_status = this.entryValue(user.employment_status)
				user.day_to_day_support = this.entryValue(user.day_to_day_support)
				user.intoxicants_used = this.entryValue(user.intoxicants_used)
				user.internet_tech_currently_used = this.entryValue(user.internet_tech_currently_used)
				user.have_you_experienced = this.entryValue(user.have_you_experienced)
				user.upsi_dates = this.entryValue(user.upsi_dates)
			}

			return user
		},
		fullName() {
			let name = ''

			// Set users full name with entry data
			if ( this.entry ) {
				const firstName = this.entry.first_name
				const lastName = this.entry.last_name
				name = `${firstName} ${lastName}`
			}
			return name
		},
		safeguarding() {
			let risks = null

			// Flag any risks that are provided
			if ( this.entry ) {
				risks = Object.values(this.entry.safeguarding_flags)
			}

			return risks
		}
	},
	watch: {
		getAdminError(value) {
			if ( value != '' ) {
				this.error = value
			}
		},
		copied(oldValue, newValue) {
			// Show copied badge
			if (oldValue != newValue) {
				setTimeout(() => {
					this.copied = false
				}, 1500)
			}
		}
	},
	methods: {
		entryValue(value) {
			/**
			 * Converts entry values
			 * Array or Object to readable spans of text
			 */
			if ( Array.isArray(value) ) {
				let values = ''
				const iterator = value.values()
				for (const value of iterator) {
					values += `<span class="d-block mb-1">${value}</span>`
				}
				return values
			} else if ( typeof value === 'object' && value != null ) {
				let values = ''
				const iterator = Object.values(value)
				for (const value of iterator) {
					values += `<span class="d-block mb-1">${value.human}</span>`
				}
				return values
			} else {
				return value
			}
		},
		appointmentTime(appointment) {
			let time = ''

			// Human readable time from timestamp
			if ( appointment ) {
				const date = new Date(appointment * 1000)
				const timeString = date.toTimeString()
				const splitTime = timeString.slice(0, 5)
				
				time = splitTime
			}

			return time
		},
		appointmentDate(appointment) {
			let time = ''

			// Human readable date from timestamp
			if ( appointment ) {
				const date = new Date(appointment * 1000)
				const day = date.getDate()
				const month = date.getMonth() + 1
				const year = date.getFullYear()

				time = `${day}/${month}/${year}`
			}

			return time
		},
		copyValue(id) {
			// Table data value of user information
			const content = document.getElementById(id)

			if ( content ) {
				// Select range of content area
				const range = document.createRange()
				range.selectNode(content)

				// Clear selection then add range
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)

				// Document execute copy command
				document.execCommand('copy')

				// Update copy text and copied value
				this.copied = true

				// Then clear & deselect range
				window.getSelection().removeAllRanges()

				this.$announcer.polite('User content copied.')
			}
		},
		getUserID() {
			this.userId = this.$route.params.id
		},
		async getEntry() {
			const clinic = localStorage.getItem('user-clinic') === null ? this.getUserClinic : localStorage.getItem('user-clinic')
			const result = await this.$api.get(
				`/v1/entries/${clinic}/${this.userId}`
			)

			if ( result ) {
				this.entry = result.data.data
				this.time = result.data.data ? result.data.data.appointment_timestamp : ''
				this.loading = false
			}
		}
	},
	mounted() {
		this.getEntry()
	},
	created() {
		this.getUserID()
	}
}
</script>