<template>
	<div class="dashboard">
		<nav-bar />

		<section class="container">
			<div class="row">

				<sideBar />

				<main role="main" class="fade-page-content col-md-10 ml-sm-auto col-lg-10 px-4">
          <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 v-if="title" class="h2 mr-3 font-weight-bold">{{ title }}</h1>
						<tool-bar v-if="toolbar" />
          </div>

					<div v-if="subtitle" class="pt-2 pb-2 mb-3 border-bottom">
						<p>{{ subtitle }}</p>
					</div>

					<!-- Content Slot -->
					<slot></slot>
				</main>

			</div>
		</section>

		<!-- Delete Confirmation Modal -->
		<modal :users="isAdmin" />
	</div>
</template>

<script>
// Admin Components
import navBar from './nav-bar'
import sideBar from './side-bar'
import toolBar from './tool-bar'
import modal from './modal'

export default {
	components: {
		navBar,
		sideBar,
		toolBar,
		modal
	},
	props: {
		title: {
			default: 'Dashboard',
			type: String
		},
		subtitle: {
			default: '',
			type: String
		},
		toolbar: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		isAdmin() {
			return this.$route.name === 'UpdateAdmin'|| this.$route.name === 'Admins'  ? true : false
		}
	}
}
</script>