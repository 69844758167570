<template>
	<!-- Delete Confirmation -->
	<transition name="modal">
		<div v-if="getShowModal" class="modal d-block" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">{{ title }}</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<span>{{ content }}</span>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-link" data-dismiss="modal" @click="closeModal">Close</button>
						<button type="button" class="btn btn-primary" @click="setDelete">Confirm</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
// State
import { mapGetters } from 'vuex'

export default {
	props: {
		users: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		...mapGetters([
			'getShowModal',
			'getEntry',
			'getUserClinic'
		]),
		title() {
			return this.users ?
				'Delete user' : 'Delete entry'
		},
		content() {
			return this.users ?
				'Please confirm we can now delete this user?' :
				'Please confirm we can now delete this record?'
		}
	},
	methods: {
		setDelete() {
			if ( this.users ) {
				this.deleteUser()
			} else {
				this.deleteEntry()
			}
		},
		async deleteUser() {
			// Remove deletion modal
			this.$store.dispatch('showModal', false)

			// Check ID
			const id = this.$route.params.id ? this.$route.params.id : this.getEntry

			// Commit to deletion
			const response = await this.$api.delete(
				'/v1/user', {
					params: {
						username: id
					}
				}
			)

			if ( response ) {
				// Proceed back to users
				if ( this.$route.name != 'Admins' ) {
					await this.$router.push(
						this.$GLOBALS.PATH_USERS
					)
				} else {
					await this.$router.go()
				}
			}
		},
		async deleteEntry() {
			// Remove deletion modal
			this.$store.dispatch('showModal', false)

			// Check ID
			const id = this.$route.params.id ? this.$route.params.id : this.getEntry

			// Commit to deletion
			const clinic = localStorage.getItem('user-clinic') === null ? this.getUserClinic : localStorage.getItem('user-clinic')
			const response = await this.$api.delete(
				`/v1/entries/${clinic}/${id}`
			)

			// Commit refresh of entries after redirecting back to dashboard
			if ( response ) {
				// Remove entry data
				this.$store.dispatch('setEntry', '')
				// Proceed back to dashboard
				if ( this.$route.name != 'Dashboard' ) {
					await this.$router.push(
						this.$GLOBALS.PATH_DASHBOARD
					)
				} else {
					await this.$router.go()
				}
			}
		},
		closeModal() {
			this.$store.dispatch('showModal', false)
		}
	}
}
</script>

<style>
.modal {
	background-color: rgba(0, 0, 0, 0.7);
}
</style>