<template>
	<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-primary p-0 shadow-sm">
		<!-- Nav Brand -->
		<router-link
			:to="$GLOBALS.PATH_DASHBOARD"
			class="navbar-brand col-sm-3 col-md-2 mr-0 w-auto"
		>Brook {{ clinic }}</router-link>

		<!-- Nav Toggle -->
		<div class="px-3 d-block d-md-none">
			<button
				@click="showMenu"
				class="btn btn-sm btn-outline-light"
				type="button"
				data-toggle="collapse"
				data-target="#navbarNav"
				aria-controls="navbarNav"
				:aria-expanded="isMenuActive ? 'true' : 'false'"
				aria-label="Toggle navigation"
			>
				Menu
			</button>
		</div>

		<!-- Nav menu -->
		<transition name="menu">
			<div
				v-show="isMenuActive"
				:class="isMenuActive ? 'show' : ''"
				class="collapse navbar-collapse shadow"
				id="navbarNav"
			>
				<ul class="navbar-nav px-3 py-3 py-md-0 w-auto">
					<li class="nav-item d-block d-md-none">
						<router-link
							:to="$GLOBALS.PATH_DASHBOARD"
							class="nav-link"
						>
							Dashboard
						</router-link>
					</li>		
					<li v-if="showAdminLinks" class="nav-item d-block d-md-none">
						<router-link
							:to="$GLOBALS.PATH_ADMINS"
							class="nav-link"
						>
							Admins
						</router-link>
					</li>		
					<li v-if="showAdminLinks" class="nav-item d-block d-md-none">
						<router-link
							:to="$GLOBALS.PATH_ADMIN_CREATE"
							class="nav-link"
						>
							Create admin
						</router-link>
					</li>		
					<li class="nav-item text-nowrap">
						<button
							@click="logout"
							class="btn btn-sm btn-outline-light my-2 my-md-0 "
						>
							Sign out
						</button>
					</li>
				</ul>
			</div>
		</transition>
	</nav>
</template>

<script>
// State
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			isMenuActive: false
		}
	},
	methods: {
		logout() {
			this.$store.dispatch( 'authLogout' ).then(() => {
				this.$router.push({
					path: this.$GLOBALS.PATH_LOGIN,
					query: {
						action: 'logoutSuccess'
					}
				})
			})
		},
		showMenu() {
			if ( this.isMenuActive ) {
				this.isMenuActive = false
			} else {
				this.isMenuActive = true
			}
		}
	},
	computed: {
		...mapGetters([
			'isSuperAdmin'
		]),
		showAdminLinks() {
			return this.isSuperAdmin === 'true'
		},
		clinic() {
			const clinic = localStorage.getItem('user-clinic')
			return clinic ? `- ${clinic}` : null
		}
	},
}
</script>