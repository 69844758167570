<template>
	<div class="btn-toolbar mb-2 mb-md-0">
		<!-- Toolbar Button Group -->
		<div class="btn-group mr-2" role="toolbar" aria-label="Entry Actions">
			<!-- Copy user -->
			<button
				v-if="hasCopy"
				class="btn btn-sm btn-outline-secondary"
				@click="copyUser"
			>
				<copy-icon v-if="!copied" size="1x" class="mr-1"></copy-icon>
				<check-square-icon v-else size="1x" class="mr-1"></check-square-icon>
				{{ copyText }}
			</button>
			
			<!-- Delete user -->
			<button
				class="btn btn-sm btn-outline-danger"
				@click="showModal"
			>
				<x-icon size="1x" class="mr-1"></x-icon>
				Delete
			</button>
		</div>
	</div>
</template>

<script>
// Icons
import {
	CheckSquareIcon,
	CopyIcon,
	XIcon,
} from 'vue-feather-icons'

export default {
	components: {
		CheckSquareIcon,
		CopyIcon,
		XIcon
	},
	props: {
		hasCopy: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			copyText: 'Copy',
			copied: false
		}
	},
	methods: {
		copyUser() {
			// Table body of user information
			const content = document.getElementById('user-information')

			if ( content ) {
				// Select range of content area
				const range = document.createRange()
				range.selectNode(content)

				// Clear selection then add range
				window.getSelection().removeAllRanges()
				window.getSelection().addRange(range)

				// Document execute copy command
				document.execCommand('copy')

				// Update copy text and copied value
				this.copied = true
				this.copyText = 'Copied'

				// Then clear & deselect range
				window.getSelection().removeAllRanges()

				this.$announcer.polite('User content copied.')
			}
		},
		showModal() {
			this.$store.dispatch('showModal', true)
		}
	}
}
</script>